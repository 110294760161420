import { API } from 'aws-amplify'

import handleApiError from '@/utils/handle-api-error'

const ProgramApis = {
  getAll: () => API.get('user', '/program', {}).catch(handleApiError),
  get: (handle) =>
    API.get('user', `/program/${handle}`, {}).catch(handleApiError),
  getRegistrationOptions: (id) =>
    API.get('user', `/program/${id}/registration-option`, {}).catch(
      handleApiError,
    ),
  getAllRegistrations: (noDetails) =>
    API.get(
      'user',
      noDetails
        ? '/program/registrations?noDetails=true'
        : '/program/registrations',
      {},
    ).catch(() => {}),
  join: (programId, sessionId) =>
    API.post('user', '/program/join', {
      body: {
        programId,
        sessionId,
      },
    }).catch(handleApiError),
  getUserResources: (id) =>
    API.get('user', `/program/listUsersResources/${id}`, {}).catch(
      handleApiError,
    ),
  getAllUserResources: () =>
    API.get('user', `/program/listUsersResources`, {}).catch(handleApiError),
}

export default ProgramApis

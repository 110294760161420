import classNames from 'classnames'
import { default as NextLink } from 'next/link'
import PropTypes from 'prop-types'

const Link = ({ href, children, className, noUnderline, ...props }) => {
  return (
    <NextLink href={href} passHref>
      <a
        className={classNames(`cursor:pointer `, className, {
          'no-underline hover:no-underline': noUnderline,
          'hover:underline': !noUnderline,
        })}
        {...props}
      >
        {children}
      </a>
    </NextLink>
  )
}

export default Link

Link.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  noUnderline: PropTypes.bool,
}

Link.defaultProps = {
  className: '',
  noUnderline: false,
}

import ms from 'ms'
import { useEffect, useState } from 'react'

const useTime = (updateInterval = ms('1s')) => {
  const [now, updateNow] = useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => updateNow(Date.now()), updateInterval)

    return () => clearInterval(interval)
  }, [updateInterval])

  return now
}

export default useTime

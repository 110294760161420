import { clone } from 'ramda'

// eslint-disable-next-line import/prefer-default-export
export const getSessionReminders = (registrationData, currentTime) =>
  registrationData
    .reduce((acc, current) => {
      const program = clone(current)
      const arr = program.registrationOption.schedule.filter((schedule) => {
        const endTime = new Date(schedule.startTime)
        endTime.setHours(endTime.getHours() + 1)
        const startTime = new Date(schedule.startTime)
        const now = new Date(currentTime)

        return startTime <= now && now < endTime
      })
      if (arr.length >= 1) {
        program.registrationOption.schedule = [arr[0]]
        return acc.concat(program)
      }
      return acc
    }, [])
    .reduce((acc, program) => {
      const obj = {
        title: program.program.title,
        programId: program.program.id,
        registrationOption: program.registrationOption,
      }

      return acc.concat(obj)
    }, [])

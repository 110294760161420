import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Auth } from 'aws-amplify'
import Router, { useRouter } from 'next/router'
import { propOr } from 'ramda'
import { Fragment } from 'react'

import Logo from '@/assets/logo/logo.png'
import Button, { ButtonVariant } from '@/components/button'
import Link from '@/components/link'
import AppBarLinks from '@/config/app-bar-links'

import useAuth from '../../user/stores/use-auth'
import useProfile from '../../user/stores/use-profile'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const profileSelector = propOr({}, 'profile')

const noLogoPages = ['/']
const lightIconPages = ['/']

const AppBar = () => {
  const { pathname } = useRouter()
  const currentUser = useAuth.use.current()
  const { name, email, avatar } = useProfile(profileSelector)
  const logOut = () => {
    Auth.signOut().then(() => Router.reload())
  }
  return (
    <>
      <Popover as='nav' className='fixed top-2.5 z-30 w-full'>
        {({ open }) => (
          <div className='mx-auto w-full max-w-7xl pl-14 pr-12 sm:px-6 md:px-0'>
            <div className='h-custom flex w-full justify-between'>
              <div className='flex w-full flex-row-reverse justify-between md:w-auto'>
                <div className='mr-8 flex w-full -translate-x-2 items-center'>
                  <Link href='/'>
                    <img className='logo' src={Logo.src} alt='Workflow' />
                  </Link>
                </div>
              </div>
              <div className='flex items-center'>
                <Popover.Button className='h-button inline-flex items-center justify-center rounded-md p-2 pr-0 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-theme-blue'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon
                      className='h-icon block'
                      aria-hidden='true'
                      color={
                        lightIconPages.includes(pathname) ? 'white' : 'black'
                      }
                    />
                  ) : (
                    <MenuIcon
                      className='h-icon block'
                      aria-hidden='true'
                      color={
                        lightIconPages.includes(pathname) ? 'white' : 'black'
                      }
                    />
                  )}
                </Popover.Button>
                <div className='hidden sm:hidden'>
                  {AppBarLinks.map(({ name: linkName, route }) => (
                    <Link
                      key={linkName}
                      href={route}
                      className='inline-flex items-center px-1 text-sm font-medium text-gray-900'
                    >
                      {linkName}
                    </Link>
                  ))}
                </div>
                {!currentUser ? (
                  <div className='flex-shrink-0 '>
                    <Link href='/auth/login' className='relative mx-3 block'>
                      <Button
                        variant={ButtonVariant.Primary}
                        bg='bg-[#f59175]'
                        tailFill='#f59175'
                        className='cm-bg !px-1.5 !py-1 !text-base'
                        tailClassName='right-[unset] left-0 scale-x-[-1] -translate-x-[50%] translate-y-[41%] h-2'
                      >
                        <span className='custom-font translate-y-0.5 !font-semibold'>
                          SIGN IN
                        </span>
                      </Button>
                    </Link>
                  </div>
                ) : (
                  <div className='flex-shrink-0 '>
                    <span className='relative mx-3 block'>
                      <Button
                        variant={ButtonVariant.Primary}
                        bg='bg-[#f59175]'
                        tailFill='#f59175'
                        className='cm-bg !px-1.5 !py-1 !text-base'
                        onClick={() => logOut()}
                        tailClassName='right-[unset] left-0 scale-x-[-1] -translate-x-[50%] translate-y-[41%] h-2'
                      >
                        <span className='custom-font translate-y-0.5 !font-semibold'>
                          SIGN OUT
                        </span>
                      </Button>
                    </span>
                  </div>
                )}
              </div>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute left-1/2 w-min min-w-[150px] text-[#234370] sm:mr-[70px] sm:w-[338px]'>
                <div className='menu-font rounded-3xl bg-gray-50 py-5 shadow-2xl'>
                  <div className='flex flex-wrap justify-center px-4 sm:px-6'>
                    {AppBarLinks.map(({ name: linkName, route }) => (
                      <Popover.Button
                        key={linkName}
                        as={Link}
                        href={route}
                        className='block w-full py-2 pl-3 pr-4 font-medium sm:pl-5 sm:pr-6'
                      >
                        +{linkName}
                      </Popover.Button>
                    ))}
                  </div>
                  {Boolean(currentUser) && (
                    <div className='mt-[20px] border-t-2 border-dotted border-[#f59175] pt-[20px]'>
                      <div className='flex items-center justify-center px-4 sm:px-6'>
                        <div className='flex-shrink-0'>
                          <img className='avatar' src={avatar} alt='' />
                        </div>
                        <div className='ml-3 break-all'>
                          <div className='username-font pt-3 font-medium'>
                            {name}
                          </div>
                          <div className='email-font font-medium'>{email}</div>
                        </div>
                      </div>
                      <div className='mt-3 flex flex-wrap justify-center space-y-1'>
                        <div className='settings flex-no-wrap flex'>
                          <Popover.Button
                            as='a'
                            href='/dashboard/profile'
                            className='inline-block border-r-[1px] border-theme-blue px-4 py-2 font-medium hover:text-gray-800 sm:px-6'
                          >
                            My Profile
                          </Popover.Button>
                          <Popover.Button
                            as='a'
                            href='/dashboard/settings'
                            className='inline-block px-4 py-2 font-medium hover:text-gray-800 sm:px-6'
                          >
                            Settings
                          </Popover.Button>
                        </div>
                        <Popover.Button
                          as='span'
                          className='ml-28 block w-max px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 sm:ml-36 sm:px-6'
                        >
                          <Button
                            variant={ButtonVariant.Primary}
                            tailFill='#ccc'
                            bg='bg-[#ccc]'
                            className=' text-base font-medium uppercase leading-3 sm:text-[13px] sm:leading-4'
                            tailClassName='right-[unset] left-0 scale-x-[-1] -translate-x-[50%] translate-y-[41%] h-2'
                            onClick={() => logOut()}
                          >
                            Sign out
                          </Button>
                        </Popover.Button>
                      </div>
                    </div>
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
      <style jsx>{`
        .custom-font {
          font-size: calc(12px + (20 - 12) * (100vw - 375px) / (1920 - 375));
        }
        .menu-font {
          font-size: calc(16px + (30 - 16) * (100vw - 375px) / (1920 - 375));
          line-height: calc(12px + (30 - 12) * (100vw - 375px) / (1920 - 375));
        }
        .username-font {
          font-size: calc(16px + (30 - 16) * (100vw - 375px) / (1920 - 375));
        }
        .email-font {
          font-size: calc(10px + (20 - 10) * (100vw - 375px) / (1920 - 375));
        }
        .avatar {
          height: calc(30px + (80 - 30) * (100vw - 375px) / (1920 - 375));
          width: calc(30px + (80 - 30) * (100vw - 375px) / (1920 - 375));
        }
        .settings {
          font-size: calc(14px + (30 - 14) * (100vw - 375px) / (1920 - 375));
          line-height: calc(12px + (30 - 12) * (100vw - 375px) / (1920 - 375));
        }
      `}</style>
    </>
  )
}

export default AppBar

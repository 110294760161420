import '@/styles/globals.css'

import Head from 'next/head'
import Script from 'next/script'
import { DefaultSeo } from 'next-seo'
import PropTypes from 'prop-types'

import initializeAmplify from '@/apis/amplify/initialize'
import defaultSeoConfig from '@/config/default-seo'
import Modules from '@/modules/index'

initializeAmplify()

const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_ID

const App = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Head>
      <DefaultSeo {...defaultSeoConfig} />
      <Script
        id='gtag-manager-script'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTAG_ID}');
          `,
        }}
      />
      <Modules>
        <Component {...pageProps} />
      </Modules>
    </>
  )
}

export default App

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  Component: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.object.isRequired,
}

import ProgramApis from '@/app/program/apis'
import createStore from '@/utils/store/create-store'

const useRegistrations = createStore((set) => ({
  userRegistrations: [],
  getAll: async () => {
    const data = (await ProgramApis.getAllRegistrations()) || []
    set({ userRegistrations: data })
  },
}))

export default useRegistrations

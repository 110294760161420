import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import AppBar from './app-bar'

const hideNavigationOnPathnames = ['/program/[handle]/session/[sessionId]']

const Navigation = ({ children }) => {
  const { pathname } = useRouter()

  const hide = useMemo(
    () => hideNavigationOnPathnames.includes(pathname),
    [pathname],
  )

  return (
    <div className='flex h-full w-full flex-wrap bg-gray-50'>
      <div className='flex-[0_0_100%]'>
        {children}
        {!hide && <AppBar />}
      </div>
    </div>
  )
}

export default Navigation

Navigation.propTypes = {
  children: PropTypes.element.isRequired,
}

import ms from 'ms'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'

import Button from '@/components/button'
import useTime from '@/hooks/use-time'

import useRegistrations from './stores/useRegistrations'
import { getSessionReminders } from './utils'

const SessionReminder = ({ children }) => {
  const registrations = useRegistrations.use.getAll()
  const router = useRouter()
  const data = useRegistrations.use.userRegistrations()
  const time = useTime(ms('5s'))

  const [hide, toggleHide] = useState(false)

  const sessionArr = useMemo(
    () => (data.length >= 1 ? getSessionReminders(data, time) : []),
    [data, time],
  )

  useEffect(() => {
    registrations()
  }, [registrations])

  return (
    <div className='h-full w-full'>
      {children}
      {!hide &&
        router.pathname !== '/program/[handle]/session/[sessionId]' &&
        sessionArr.length >= 1 && (
          <div className='fixed top-0 right-0 z-40 mx-8 mt-24 flex max-w-[35em] flex-col items-end rounded-xl border border-gray-600 bg-white p-8 pb-4 shadow md:mx-24 md:mt-48'>
            <p className='text-lg'>
              Hey, You have an ongoing session: {sessionArr[0].title}. Click the
              button below to enter the classroom!
            </p>
            <div className='flex items-center space-x-4'>
              <Button className='mt-4 text-lg' onClick={() => toggleHide(true)}>
                Hide
              </Button>
              <a
                href={`/program/${sessionArr[0].programId}/session/${sessionArr[0].registrationOption.schedule[0].id}`}
              >
                <Button className='mt-4 flex w-fit items-center justify-center whitespace-nowrap rounded border border-transparent bg-theme-blue px-8 py-2 text-base font-medium text-white hover:bg-theme-blue md:py-2 md:px-10 md:text-lg'>
                  Join Session
                </Button>
              </a>
            </div>
          </div>
        )}
    </div>
  )
}

export default SessionReminder

SessionReminder.propTypes = {
  children: PropTypes.element.isRequired,
}

import PropTypes from 'prop-types'
import * as React from 'react'

const Tail = ({ fill, ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.4 18.15' {...props}>
    <g data-name='Layer 2'>
      <path
        d='M14.61 6 11 0 0 9l7.41 4.73a28.56 28.56 0 0 0 15.31 4.42 29.05 29.05 0 0 0 7.68-1A28.81 28.81 0 0 1 14.61 6Z'
        style={{
          fill: fill || '#fec786',
        }}
        data-name='Layer 1'
      />
    </g>
  </svg>
)

export default Tail

Tail.propTypes = {
  fill: PropTypes.string.isRequired,
}

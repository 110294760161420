import { API } from 'aws-amplify'
import Router from 'next/router'
import { propOr } from 'ramda'

import handleApiError from '@/utils/handle-api-error'
import createStore from '@/utils/store/create-store'

const useProfile = createStore((set, get) => ({
  profile: undefined,
  loadProfile: async () => {
    const data = await API.get('user', '/profile', {}).catch(handleApiError)
    set({ profile: data })
  },
  updateProfile: async (data) =>
    API.put('user', '/profile', { body: data })
      .then(() => get().loadProfile())
      .then(() => Router.push('/dashboard/profile'))
      .catch(handleApiError),
  updateAvatarIcon: async (data) =>
    API.put('user', '/profile', { body: data })
      .then(() => get().loadProfile())
      .then(() => Router.push('/dashboard/profile/edit'))
      .catch(handleApiError),
  chooseAvatarIcon: async (data) =>
    API.put('user', '/profile', { body: data })
      .then(() => get().loadProfile())
      .then(() => Router.push('/dashboard/profile/choose-your-avatar'))
      .catch(handleApiError),
  getAvatarUploadUrl: () =>
    API.post('user', '/profile/avatar/upload', {})
      .then(propOr('', 'url'))
      .catch(handleApiError),
  getCoverUploadUrl: () =>
    API.post('user', '/profile/cover/upload', {})
      .then(propOr('', 'url'))
      .catch(handleApiError),
}))

export default useProfile

const AppBarLinks = [
  {
    name: 'About',
    route: '/about',
    external: false,
  },
  {
    name: 'Team',
    route: '/team',
    external: false,
  },
  {
    name: 'Dashboard',
    route: '/dashboard',
    external: false,
  },
  {
    name: 'Courses',
    route: '/program',
    external: false,
  },
  {
    name: 'Contact',
    route: '/contact',
    external: false,
  },
]

export default AppBarLinks
